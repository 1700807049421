.modalGeneralTitles{
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    text-transform: capitalize;
    max-width: 80%;
    margin: 0 auto !important;
}

.modalGeneralTitles svg{
    display: block;
    width: 100% !important;
    font-size: 50px;
    fill: #1976d2;
    margin-bottom: 10px;
}

.modalIconTitle{
    display: block;
}

.MuiBox-root{
    width: 600px;
    max-width: 100%;
    margin: 0 auto;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
 
  