.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #282c34;
    color: white;
  }
  
  .projectName {
    font-size: 1.5rem;
  }
  
  .logoutButton {
    background: none;
    border: none;
    color: white;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .logoutButton:hover {
    text-decoration: underline;
  }
  