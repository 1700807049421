.alertMessages div{
    margin-bottom: 0px;
}

.alertBoxes{
    bottom: 30px;
    right: 30px !important;
    left: unset !important;
    width: max-content;
    max-width: 100%;
    margin-bottom: 0px !important;
}

.alertBoxes div{
    margin-bottom: 0px !important;
}