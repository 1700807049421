.modalMainHeading {
  text-align: center;
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #000000;
  padding-top: 0px !important;
}

.multiSelectTable td,
.multiSelectTable th {
  padding: 20px 5px;
  border-bottom: none;
}

.multiSelectTable th {
  font-weight: bold;
  color: #000000;
}

.multiSelectTable tr {
  vertical-align: top;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.multiSelectTable p {
  margin-top: 0px;
  margin-bottom: 15px;
}

.multiSelectImage img {
  border: 1px solid rgba(224, 224, 224, 1);
}

.multiSelectImage {
  width: 15%;
}

.multiSelectImage label {
  margin-bottom: 5px !important;
}

.multiSelectQuantity {
  width: 15%;
}

.multiSelectTitles {
  width: 35% !important;
}

.multiSelectPrice {
  width: 20%;
}


.multiEbayDesc>div {
  max-height: 400px;
  overflow-y: auto;
}

.multiIsSync {
  width: 15%;
}

.variableChildProduct label {
  margin-top: 0px !important;
}

.multiSelectCategory label {
  background-color: #ffffff;
  font-size: 14px;
  line-height: 17px;
  padding: 0px 5px;
}

.multiSelectCategory>div>div>div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.multiSelectCategory>div>div>div>div {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.multiSelectTable label {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-left: 0px;
  background-color: transparent !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  /* flex-flow: column;
  align-items: flex-start; */
}

/* .titlesHeight {
  display: block;
} */

.multiSelectTable input {
  height: 40px;
  padding: 0px 16px;
  font-size: 14px;
  color: #000000;
}

.topZero {
  margin-top: 0px !important;
}

.modalProductDesc {
  height: 100%;
}

.multiSelectStyles>div {
  padding-right: 58px;
}

.multiActionBtns {
  display: flex;
  gap: 15px;
  flex-flow: column;
}

.multiActionBtns button {
  width: max-content;
  max-width: 100%;
}

.variableChildProduct .titlesHeight {
  min-height: unset;
}

.multiSelectImage img {
  width: 80px;
  height: 80px;
  min-width: 50px;
  min-height: 50px;
}

.textRed {
  color: red;
}

.confirmationContent {
  padding: 0px !important;
  height: 88%;
}

.textCenter {
  text-align: center;
}

.confirmationBtns {
  justify-content: center !important;
}

.closeModalBtn {
  position: absolute;
  top: 10px;
  right: 10px;
}

.viewFullDesc {
  padding: 0px !important;
  background-color: transparent !important;
}

.ecwidLabels {
  margin-top: 0px !important;
  white-space: nowrap;
}

.multiSelectImage .ecwidLabels {
  margin-bottom: 10px !important;
}



.cancelBtns {
  background-color: #bdbdbd !important;
}

.deleteCombination {
  background-color: red !important;
  padding: 0px !important;
  width: 35px !important;
  height: 35px !important;
  border-radius: 100% !important;
  min-width: 35px !important;
  margin-left: auto !important;
  margin-right: 20px !important;
  float: right;
}

.infoIconField {
  position: relative;
}

.infoIconField button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
  z-index: 9;
  padding: 0px !important;
}

.infoIconField svg {
  font-size: 14px;
}

.radio-group-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.custom-text-field {
  width: 100%;
  /* Adjust as needed */
}

.radio-group-container .MuiFormControlLabel-root {
  margin-right: 20px;
  /* Space between radio buttons */
  white-space: nowrap;
  /* Ensure label text doesn't wrap */
}

.customDialogPaper {
  max-width: 100%;
  padding: 20px 24px;
}

.titleContainer {
  display: flex;
  align-items: normal;
  gap: 5px;
}

.titlesHeight {
  font-size: 14px;
}

.ecwidLabels {
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
  overflow: visible;
  /* Prevents Title from wrapping */
}

.productName {
  font-size: 14px;
  font-weight: normal;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}