.modalContent {
    padding: 20px;
    background: #ffffff;
    border-radius: 8px;
    max-width: 100%;
    max-height: 90vh; /* Make modal height responsive */
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 700px;
  }
  
  .modalMainHeading{
    text-align: center;
    font-size: 20px !important;
    font-weight: 700 !important;
    color: #000000;
    width: 80%;
    max-width: 100%;
    margin: 0 auto 10px;
  }

  .cancelBtns{
    background-color: #bdbdbd !important;
  }

  .richTextEditor {
    height: 500px; /* Set the height to your desired value */
    overflow-y: auto;
    padding: 20px 0px 30px;
  }

  .richTextEditor .ql-picker-options{
    position: absolute;
    top: 100%;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 0px 15px
  }
  
  button {
    margin-left: 10px;
  }
  
  .richEditorModal{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .richTextEditor div{
    font-size: 14px;
  }