.container {
    padding: 20px;
}

.title {
    font-size: 24px;
    margin-bottom: 20px;
}

h2 {
    font-size: 20px;
    margin-top: 20px;
}

p {
    margin-bottom: 10px;
}