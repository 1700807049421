.multiSelectStyles > div{
    height: 50px !important;
    padding: 0px 58px 0px 15px !important;
}

.multiSelectCategory div{
    height: 50px !important;
}

.multiSelectCategory > div > div > div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.multiSelectCategory > div > div > div > div{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.multiSelectCategory label{
  background-color: #ffffff;
  font-size: 14px;
  line-height: 17px;
}

.categoryClearIcon{
  position: absolute !important;
  top: 50%;
  transform: translateY(-50%);
  right: 25px;
}