.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.form {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.form h2 {
  margin-bottom: 1.5rem;
  color: #333;
  text-align: left;
}

.form div {
  margin-bottom: 1rem;
}

.passwordContainer {
  position: relative;
}

.passwordContainer input {
  width: 100%;
  padding-right: 2rem;
}

.eyeIcon {
  position: absolute;
  top: 60%;
  right: 10px;
  cursor: pointer;
}

.form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 700;
  color: #000000;
  font-size: 14px;
}

.form input {
  width: 100%;
  padding: 0px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #000000;
  height: 50px;
}

.form button {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 0.875rem !important;
  line-height: 1.75;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-top: 1rem;
  font-weight: 600 !important;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.form button:hover {
  background-color: #0056b3;
}

.forgotPasswordContainer {
  text-align: left;
  margin-top: 0.5rem;
}

.forgotPassword {
  color: #007bff;
  text-decoration: none;
  font-size: 0.875rem;
}

.forgotPassword:hover {
  color: #0056b3;
  text-decoration: underline;
}

.mainHeadings {
  text-align: center !important;
  font-size: 30px !important;
  font-weight: 700 !important;
  margin-bottom: 30px !important;
  display: block;
  margin-top: 0px;
}

.loginBtn{
  margin-left: 0px !important;
}